import React from 'react';
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Label from './label';

const TextAreaField = ({name, rows, placeholder, required, handlechange, fieldClass, label, grpmd}) => (
  <Form.Group as={Col} md={grpmd} controlId="validation{name}">
    <Label label={label} required={required} />
    <Form.Control
      className={fieldClass}
      name={name}
      required={required}
      as="textarea"
      rows={rows}
      onChange={handlechange}
      placeholder={placeholder} 
    />
  </Form.Group>
);

export default TextAreaField;
import React from 'react';
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Label from './label';

const SelectField = ({name, required, placeholder, values, handlechange, fieldClass, label, grpmd, componentprops}) => (
  <Form.Group as={Col} md={grpmd} controlId={`validation${name}`}>
    <Label label={label} required={required} />
    <Form.Control
		className={`custom-select ${fieldClass}`}
		required={required}
		name={name}
		onChange={handlechange}
		as="select"
		>
		{values.map(val =><option value={val} key="" 
    selected={componentprops[name] === val}>{val}</option>)}
    </Form.Control>
  </Form.Group>

);

export default SelectField;
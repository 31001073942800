import React from 'react';
import { Form } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

const Label = ({label, required, type}) => (
    <>
    { type === "checkbox" && label ?
        <Form.Label>Subscribe to Able Agent. <a target="_blank" href='/getting-into-property-a-sound-foundation-for-those-looking-at-getting-into-the-real-estate-sector'>Click here</a> for more details</Form.Label>
        : <Form.Label>{label}{required ? <span className="required"> *</span> : ''}</Form.Label>
        
    }
    </>
);

export default Label;


import React from 'react'
import { Button } from "react-bootstrap"

const ButtonField = ({type, name, value, fieldClass, groupClass}) => (
	<div className={groupClass}>
		<Button variant="" type={type} className={fieldClass}>
			{name}
		</Button>
	</div>
);

export default ButtonField;
import React from 'react';
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Label from './label';

const InputField = ({fieldClass, type, nolabel, name, value, required, placeholder, handlechange, pattern, label, grpmd, groupClass}) => (
  <Form.Group as={Col} md={grpmd} className={type === "checkbox" ? 'check-blk' : groupClass} controlId={"validation" + name}>
{type === "checkbox" ? '' : nolabel ? '' :  <Label type={type} label={label} required={required} />}
    <Form.Control
      className={fieldClass}
      required={required}
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={handlechange}
      pattern={pattern}
    />
    {type === "checkbox" ? <Label type={type} label={label} required={required} /> : nolabel ? '' : ''}
  </Form.Group>
);

export default InputField;